import {
  mdiCogOutline,
  mdiAccountOutline,
  mdiHammerWrench,
  mdiPackageVariantClosed,
  mdiCalendarCursor,
  mdiDatabaseOutline,
  mdiGoogleAnalytics,
  mdiFacebook,
  mdiYoutube,
  mdiCalendarRangeOutline,
  mdiMessageOutline,
  mdiEmailOutline,
  mdiCalendarCheckOutline,
  mdiFileDocumentOutline,
  mdiFileOutline,
  mdiMessageTextOutline,
  mdiCheckboxBlankCircleOutline,
  mdiHomeOutline,
  mdiCalendarOutline,
  mdiCheckboxMarkedCircleOutline,
  mdiContentCopy,
  mdiEyeOffOutline,
  mdiLifebuoy,
  mdiMenu,
  mdiShieldOutline,
  mdiAlphaTBoxOutline,
  mdiArchiveOutline,
  mdiControllerClassicOutline,
  mdiCreditCardOutline,
  mdiEyeOutline,
  mdiChartDonut,
  mdiMapOutline,
  mdiFile,
  mdiMessage,
  mdiElectronFramework,
  mdiFormatListChecks,
  mdiClipboardList,
  mdiProgressClock,
  mdiCheckboxMarkedCirclePlusOutline,
  mdiCalendarCheck,
  mdiClose,
  mdiEye,
  mdiEyeOff,
  mdiPlus,
  mdiSquareEditOutline,
  mdiCloudDownload,
  mdiChevronDown,
  mdiCircleSmall,
} from '@mdi/js'

import * as icons from '@mdi/js'

export const getIcon = iconName => {
  const iconMap = {
    mdiCogOutline,
    mdiAccountOutline,
    mdiHammerWrench,
    mdiPackageVariantClosed,
    mdiCalendarCursor,
    mdiDatabaseOutline,
    mdiGoogleAnalytics,
    mdiFacebook,
    mdiYoutube,
    mdiCalendarRangeOutline,
    mdiMessageOutline,
    mdiEmailOutline,
    mdiCalendarCheckOutline,
    mdiFileDocumentOutline,
    mdiFileOutline,
    mdiMessageTextOutline,
    mdiCheckboxBlankCircleOutline,
    mdiHomeOutline,
    mdiCalendarOutline,
    mdiCheckboxMarkedCircleOutline,
    mdiContentCopy,
    mdiEyeOffOutline,
    mdiLifebuoy,
    mdiMenu,
    mdiShieldOutline,
    mdiAlphaTBoxOutline,
    mdiArchiveOutline,
    mdiControllerClassicOutline,
    mdiCreditCardOutline,
    mdiEyeOutline,
    mdiChartDonut,
    mdiMapOutline,
    mdiFile,
    mdiMessage,
    mdiElectronFramework,
    mdiFormatListChecks,
    mdiClipboardList,
    mdiProgressClock,
    mdiCheckboxMarkedCirclePlusOutline,
    mdiCalendarCheck,
    mdiClose,
    mdiEye,
    mdiEyeOff,
    mdiPlus,
    mdiSquareEditOutline,
    mdiCloudDownload,
    mdiChevronDown,
    mdiCircleSmall,
  }

  return iconMap[iconName]
}

export const getIcons = () => Object.keys(icons)

export const resolveIconName = iconName => iconName.replace(/([A-Z])/g, '-$1').toLowerCase()
