export default {
  namespaced: true,
  state: {
    shallContentShowOverlay: false,
    menuRouteLoaded: false,
    navTree: {},
  },
  getters: {},
  mutations: {
    TOGGLE_CONTENT_OVERLAY(state, value) {
      state.shallContentShowOverlay = value !== undefined ? value : !state.shallContentShowOverlay
    },
    MENU_ROUTE_LOADED(state, val) {
      state.menuRouteLoaded = val
    },
    UPDATE_NAV_TREE(state, val) {
      state.navTree = val
    },
  },
  actions: {},
}
