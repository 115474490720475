export const toString = v => Object.prototype.toString.call(v)
export const isFunction = fun => typeof fun === 'function'
export const isRefExp = val => toString(val) === '[object Regexp]'

export const isObject = val => toString(val) === '[object Object]'
export function isString(val) {
  return typeof val === 'string'
}
export function isBoolean(val) {
  return typeof val === 'boolean'
}

export const isNull = val => toString(val) === '[object Null]'
